'use strict';

(function() {
  function OrganizationResource($resource) {
    return $resource('api/v1/orgs/:id/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      update: {
        method: 'PUT'
      }
    });
  }

  angular.module('windmanagerApp')
    .factory('Organization', OrganizationResource);

})();
